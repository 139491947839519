import React from 'react'
import Seo from '@widgets/Seo'
import Divider from '@components/Divider'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import CardSection from '../layout/CardSection'
import { Link } from 'gatsby'
import { Button, Container, Text } from 'theme-ui'
import Breadcrumbs from '../components/Breadcrumbs/PageCrumbs'

export default () => {
  const title = 'About'
  const styles = {
    flex: {
      display: 'flex',
    },
    running: {
      fontSize: `1.25em`,
    },
  }

  return (
    <>
      <Seo
        title='About Pacific Beauty Private Label Skincare'
        description='Pacific Beauty is a distributor of American-made private label skincare products with a specialization in international markets. We excel at helping our partners with their country’s regulatory compliance and we work with the world’s leading skincare manufacturer to deliver high-quality and scientifically-proven personal care products.'
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs title={title} />
      </Container>
      <Divider space={2} />
      <Stack>
        <Main>
          <PageTitle header='About Us' />
          <CardSection omitTitle>
            <Text sx={styles.running}>
              <strong>Pacific Beauty</strong> is an American-based company which
              specializes in the manufacturing, packaging, and distribution of
              private label bath and body products. We proudly partner with the
              owners of high-end spas, upscale salons and popular boutiques
              throughout the world to provide them with professional but
              affordable private labeling. Our goal is to help estheticians,
              cosmetologists, dermatologists, beauticians and others in the
              beauty industry supply their clients with high quality private
              labeled skincare products. Adding private label skincare products
              to your schedule of spa, salon or clinical offerings is a proven
              way to build rapport with clients, gain their loyalty and increase
              your profits. <Link to='/contact/'>Contact us</Link> today to
              learn about our turnkey private label skincare programs.
            </Text>
            <Divider space={3} />
            <Button as={Link} to='/contact/'>
              Contact Us
            </Button>
          </CardSection>
        </Main>
      </Stack>
    </>
  )
}
